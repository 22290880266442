@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins;
}

.searchBtn {
  font-size: 1.6rem;
}

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.rs-container {
  height: 100vh;
}

.rs-sidebar {
  height: 100vh;
  position: absolute;
  background: #303c54 !important;
  z-index: 9999;
  overflow: hidden;
  box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
}

.rs-sidenav-header {
  height: 60px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.rs-sidenav {
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
  /* background: #44536b !important; */
  background: #fff !important;
}
.rs-sidenav::-webkit-scrollbar {
  display: none;
}

.rs-sidenav-item:hover {
  background: #33479e !important;
  color: #fff !important;
}

.rs-sidenav-item:hover a {
  color: #fff !important;
}

.rs-dropdown-item:hover {
  background: #33479e !important;
  color: #fff !important;
}

.rs-dropdown-item:hover a {
  /* background: #33479e !important; */
  color: #fff !important;
}

.rs-table-header-row-wrapper {
  z-index: 0 !important;
}

.rs-sidenav-item-active {
  background: #33479e !important;
  color: #fff !important;
}

.nav-toggle {
  box-shadow: 2px 0 2px -2px rgba(0, 0, 0, 0.5);
}

.rs-header {
  height: 55px;
  margin-left: 55px;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 10%);
  z-index: 1;
  background: #33479e;
  color: white;
}

.rs-header h4 {
  font-weight: 200;
  font-size: 20px;
  line-height: 34px;
}

.rs-content {
  background: #f8f8f8;
  width: calc(100vw - 58px);
  margin-left: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.rs-dropdown-toggle {
  background: #44536b;
  color: #fff;
}

.rs-sidenav-nav a {
  color: #8e8e93 !important;
  text-decoration: none !important;
}

button.btn.btn-sm.btn-danger.ms-2 {
  background: #33479e;
  border: 0;
}

button.btn.btn-danger {
  border: 0;
  background: #33479e;
}

button.btn.btn-danger:hover {
  background: #000;
}

/* TABLE STYLING */
.rs-table-body-wheel-area {
  background-color: #fff !important;
}

/* LOGIN PAGE STYLE */

.loginContainer {
  background-color: #33479e;
}

.loginDiv {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  min-width: 350px;
}

/* MODAL STYLING */
.rs-modal-body {
  overflow-x: hidden !important;
  padding-bottom: 0 !important;
}

.mydiv {
  background-color: red;
}

/* SHOP TABLE STYLING */
.shopTable .rs-table-cell-content {
  padding: 7px 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopTable {
  overflow: hidden;
  background: #fff;
}

.rs-pagination-group-xs {
  font-size: 12px;
  padding: 8px !important;
}

.ProductImageDiv {
  padding: 2rem;
  border: 2px solid #ddd;
  border-radius: 2rem;
  margin: 0px 0;
  font-size: 2rem;
  position: relative;
}

.imageDeleteBtn {
  position: absolute;
  right: 0;
  top: 0;
  margin: 0.5rem;
  color: #fff;
  border-radius: 50%;
  background: red;
  padding: 0px 6px;
  cursor: pointer;
}

.imageDiv {
  position: relative;
  width: 200px;
}

.imageEdit {
  position: absolute;
  right: 5px;
}

.vh80 {
  height: 80vh;
}

.vh88 {
  height: 88vh;
}

.vh77 {
  height: 77vh;
}

.vh75 {
  height: 75vh;
}

.vh90 {
  height: 90vh;
}

.vh85 {
  height: 85vh;
}

.addHomeSlider .rs-picker-toggle-wrapper {
  width: 100% !important;
}

.cropModalContainer {
  height: 300px;
  position: relative;
}

.tablePagination {
  border-radius: 5px 5px 0 0;
}

.tableWithoutPagination {
  border-radius: 5px;
}

.rs-table-cell-content {
  /* display: flex !important;
  justify-content: center;
  align-items: center; */
  text-align: left;
}

.paginationStrip {
  border-radius: 0 0 5px 5px;
  background-color: #fff;
}

.serviceImagePreview {
  border-radius: 10px;
  overflow: hidden;
}

.imagePreview {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.imagePreviewSquare {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.imagePreview img,
.imagePreviewSquare img {
  width: 100%;
}

.editIcon {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  background: #fff;
  border-radius: 5px;
}

.uploadImageBox {
  /* width: 317px;
  height: 178px; */
  border: 0.5px dotted #ddd;
  border-radius: 10px;
  cursor: pointer;
}

.userGuideContent iframe {
  width: 100% !important;
}

.uploadImageBoxSquare {
  width: 100%;
  border: 0.5px dotted #ddd;
  border-radius: 10px;
  cursor: pointer;
}

#addShopForm .rs-picker {
  width: 100% !important;
}

#addShopForm .col-md-4 {
  margin-bottom: 25px;
}

.rs-table-row-header {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.rs-modal-wrapper {
  display: flex !important;
  align-items: center !important;
}

.rs-icon {
  font-weight: 700;
}

.closeButton {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
}

.rs-sidenav-item .rs-sidenav-item-icon {
  margin-right: 20px;
  position: absolute;
  left: 20px;
  top: 16px;
  line-height: 1.25;
  height: 20px;
  width: 20px;
}

/* .paginationStrip .rs-pagination-btn.rs-pagination-btn-active {
  color: #1675e0;
  color: #fff !important;
  background-color: #dc3545;
  border: 1px solid #1675e0;
  border: 1px solid #fff !important;
} */

.rs-sidenav-item-panel {
  padding-left: 20px !important;
  font-weight: 500 !important;
  color: #8e8e93 !important;
  margin: 10px 0 !important;
}

.shopDetailSection {
  height: 88vh;
}

.campaignImageBox {
  width: 100%;
  border: 0.5px dotted #ddd;
  border-radius: 10px;
  cursor: pointer;
}
.campaignImagePreview {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.actionCell .rs-table-cell-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideToggle {
  display: none;
}

.centerCell .rs-table-cell-content {
  display: flex !important;
  justify-content: center !important;
  align-items: center;
}

.editProductCategoryModal {
  width: 300px !important;
}

.addProductCategoryModal {
  width: 298px !important;
}

@media only screen and (max-width: 920px) {
  .rs-content {
    width: 100vw;
    margin-left: 0;
  }

  .rs-header {
    margin-left: 0;
  }

  .sideToggle {
    display: block;
  }
}

.simpleButton {
  padding: 5px;
  border-radius: 5px;
  margin-right: 20px;
}

.simpleButton:hover {
  outline: 1px solid cyan;
  background-color: #ddd;
}
